<template>
  <div 
    :style="`border-left:4px solid ${ color } !important;`" 
    class="col-xl-3 border"
  >
    <div class="m-widget25--progress p-md-3">
      <div class="m-widget25__progress">
        <span class="m-widget25__progress-number d-inline-block mb-2">
          <span
            v-tooltip="'Filter by priority'"
            @click="$eventBus.$emit('triggerListInfo_setFilter', String(priority), false)"
          >{{ allByStatus(priority).length }}</span>
          <small
            v-tooltip="'Filter by priority and unacknowledged'"
            @click="$eventBus.$emit('triggerListInfo_setFilter', String(priority), true)"
          > / {{ unacknowledgedByStatus(priority).length }}</small>
          {{ textByStatus(priority).key }}
        </span>
        <div class="progress m-progress--sm d-none d-md-block">
          <div
            :style="`width: ${ percentByStatus(priority) }%;`"
            class="progress-bar text-center"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ percentByStatus(priority) }}% acknowledged
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: 'TriggerListInfoItem',
  props: {
    priority: {
      type: Number,
      required: true
    },
    triggers: {
      type: Array,
      required: false,
      default () {
        return null;
      }
    },
    color: {
      type: String,
      required: false,
      default () {
        return 'lightgray';
      }
    }
  },
  data () {
    return {
      priorities: [
        { key: 'disaster', priority: 5 },
        { key: 'high', priority: 4 },
        { key: 'average', priority: 3 },
        { key: 'warning', priority: 2 },
      ]
    }
  },
  methods: {
    allByStatus (status) {
      if (this.triggers == null) {
        return;
      }
      return this.triggers.filter((trigger) => {
        return `${ trigger.lastEventSeverity }` === `${ status }`;
      });
    },
    unacknowledgedByStatus (status) {
      return this.allByStatus(status).filter((trigger) => {
        //return `${ trigger.lastEvent.acknowledged }` === `0`;
        return !trigger.acknowledged;
      });
    },
    textByStatus (status) {
      return this.priorities.find((priority) => {
        return priority.priority === status;
      });
    },
    percentByStatus (status) {
      let unacknowledged = this.unacknowledgedByStatus(status);
      if (unacknowledged.length === 0) {
        return 100;
      }
      let all = this.allByStatus(status);
      if (all.length === 0) {
        return 100;
      }
      return 100 - Math.floor(unacknowledged.length / (all.length / 100));
    }
  }
}
</script>

<style scoped>
.m-widget25__progress-number {
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
}
.progress {
  height: 1.7rem;
  font-size: 0.9rem;
  line-height: 1.7rem;
}
</style>
